<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Configuration" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Application name *"
                  v-model="applicationName"
                  :error="!!errors.app_name"
                  :error-messages="errors.app_name"
                  @input="delete errors.app_name"
                />
                <va-input
                  label="Site copyright *"
                  v-model="siteCopyright"
                  :error="!!errors.app_site_copyright"
                  :error-messages="errors.app_site_copyright"
                  @input="delete errors.app_site_copyright"
                />
                <va-input
                  type="textarea"
                  label="Administrator Email *"
                  v-model="administratorEmail"
                  :error="!!errors.app_email_admin"
                  :error-messages="errors.app_email_admin"
                  @input="delete errors.app_email_admin"
                />
                <va-input
                  type="textarea"
                  label="Contact email *"
                  v-model="contactEmail"
                  :error="!!errors.app_email_contact"
                  :error-messages="errors.app_email_contact"
                  @input="delete errors.app_email_contact"
                />
                <va-input
                  label="Address *"
                  v-model="address"
                  :error="!!errors.app_address"
                  :error-messages="errors.app_address"
                  @input="delete errors.app_address"
                />
                <va-input
                  type="textarea"
                  label="Phone *"
                  v-model="phone"
                  :error="!!errors.app_phones"
                  :error-messages="errors.app_phones"
                  @input="delete errors.app_phones"
                />
                <va-input
                  label="Scripts for head end"
                  v-model="scriptEndHead"
                  type="textarea"
                  :error="!!errors.app_script_head_end"
                  :error-messages="errors.app_script_head_end"
                  @input="delete errors.app_script_head_end"
                />
                <va-input
                  label="Scripts for body start"
                  v-model="scriptsBodyStart"
                  type="textarea"
                  :error="!!errors.app_script_body_start"
                  :error-messages="errors.app_script_body_start"
                  @input="delete errors.app_script_body_start"
                />
                <va-toggle small v-model="showFooterMenu" label="Show Footer Menu"></va-toggle>
                <va-toggle small v-model="showFooterSubscribe" label="Show Footer Subscribe"></va-toggle>
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      applicationName: '',
      siteCopyright: '',
      administratorEmail: '',
      contactEmail: '',
      address: '',
      phone: '',
      scriptEndHead: '',
      scriptsBodyStart: '',
      showFooterMenu: false,
      showFooterSubscribe: false,

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        app_name: this.applicationName,
        app_site_copyright: this.siteCopyright,
        app_email_admin: this.administratorEmail,
        app_email_contact: this.contactEmail,
        app_address: this.address,
        app_phones: this.phone,
        app_script_head_end: this.scriptEndHead,
        app_script_body_start: this.scriptsBodyStart,
        app_footer_menu_show: this.showFooterMenu ? 1 : 0,
        app_footer_subscribe_show: this.showFooterSubscribe ? 1 : 0,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/site-config?lang=${this.locale}`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/site-config/validate?lang=${this.locale}`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/site-config?lang=${this.locale}`)
        .then(response => {
          // console.log(response.data)
          this.applicationName = response.data.app_name
          this.siteCopyright = response.data.app_site_copyright
          this.administratorEmail = response.data.app_email_admin
          this.contactEmail = response.data.app_email_contact
          this.address = response.data.app_address
          this.phone = response.data.app_phones
          this.scriptEndHead = response.data.app_script_head_end
          this.scriptsBodyStart = response.data.app_script_body_start
          this.showFooterMenu = !!response.data.app_footer_menu_show
          this.showFooterSubscribe = !!response.data.app_footer_subscribe_show
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
